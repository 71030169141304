import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Link, useParams, useSearchParams } from "react-router-dom";
import HorizontalSlider from "react-horizontal-slider";

const Plant = () => {
  const plant = [
    {
      id: "1",
      name: "Pepper bell-bacterial spot",
      image: require("../../Assets/pepper bac-spot.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Bacterial spot",
      bioName: "Capsicum annuum",
      sentenceOne: "Tunnel-like grey lines on the leaves.",
      symptoms: `Pepper bacterial spot is a plant disease caused by the bacterium Xanthomonas campestris. The disease can infect all above-ground parts of the pepper plant including the leaves, fruit, and stems. Initially, small black or brown spots appear on the foliage and stems, which gradually enlarge and become sunken. The spots may eventually coalesce to form large, irregular lesions that can cause the leaves to yellow and wilt. In severe cases, the infected leaves may fall off the plant.

      The disease also affects the fruit causing small, water-soaked spots that may enlarge and turn dark brown or black as the disease progresses. These spots can become sunken, affecting the texture of the fruit. Infected fruit may also become distorted, making it unmarketable.
      
      Pepper bacterial spot is favored by warm, humid conditions and can spread rapidly in areas with high rainfall or irrigation. The bacteria can survive in plant debris or in the soil, making crop rotation and sanitation important control measures.
      
      Symptoms of pepper bacterial spot can be difficult to distinguish from other pepper diseases, so laboratory testing is often required for accurate diagnosis. Effective management of the disease involves the use of resistant varieties, cultural practices such as crop rotation, and the application of copper-based bactericides.`,
      images: [
        require("../../Assets/cucumber.jpg"),
        require("../../Assets/beans.jpg"),
      ],
      causes: `Bacterial spot is caused by the pathogenic bacteria Xanthomonas euvesicatoria and Xanthomonas campestris, which can survive in plant debris and soil for several years. The bacteria can enter the plant through natural openings or wounds, such as those caused by insect feeding, pruning, or weather damage. The disease can also be spread through contaminated tools or equipment, or by handling infected plants.

      Bacterial spot is favored by warm and humid conditions, and can spread rapidly in crowded or poorly ventilated growing areas. Overhead irrigation or rain can also spread the bacteria from infected plants to healthy ones. Plant stress, such as from drought, nutrient deficiencies, or extreme temperatures, can make plants more susceptible to infection.
      
      Once the bacteria infect a plant, they multiply and cause water-soaked lesions on the leaves, stems, and fruit. As the lesions expand, they become brown or black and may develop a yellow halo. The infected leaves may also curl or drop, and the fruit may become deformed or sunken. Severe infections can cause defoliation, reduced yield, and even plant death.`,
    },
    {
      id: "2",
      name: "Pepper bell-healthy",
      image: require("../../Assets/pepper-healthy.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "healthy",
      bioName: "Capsicum annuum",
      sentenceOne:
        "Dark green vibrant leaves strong and leaves without bending or breaking",
      symptoms: `Pepper bell plants are known for their healthy and vibrant appearance when they are free from any diseases or pests. The following are some of the symptoms that indicate a healthy pepper bell plant:

      Strong and sturdy stem: Healthy pepper bell plants have a strong and sturdy stem that can support the weight of the fruits and leaves without bending or breaking.
      
      Vibrant leaves: The leaves of a healthy pepper bell plant are deep green in color and are free from any discoloration or spots. They are firm and smooth to the touch.
      
      Abundant flowers: Healthy pepper bell plants produce abundant flowers that eventually turn into fruits. The flowers are brightly colored and have a pleasant aroma.
      
      Plump fruits: The fruits of a healthy pepper bell plant are plump and firm to the touch. They are free from any blemishes or discoloration.
      
      Uniform growth: Healthy pepper bell plants grow uniformly and have a balanced canopy of leaves and fruits. There are no stunted or yellowing leaves, and the plant appears lush and full.`,
      images: [
        require("../../Assets/capsicum.jpg"),
        // require("../../Assets/pepper-healthy.JPG"),
      ],
      causes: `Giving rich nutrients to plant and enough water sunlight and oxygen to plant`,
    },
    {
      id: "3",
      name: "Potato Early blight",
      image: require("../../Assets/early-blight.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Early Blight",
      bioName: "Solanum tuberosum",
      sentenceOne: "Holes and burnt on the leaf",
      symptoms: `Potato Early blight is a common fungal disease that affects potato plants. The symptoms of early blight start as small, dark brown spots on the lower leaves of the potato plant. These spots gradually increase in size and become irregular in shape, with a concentric ring pattern. The infected leaves become yellow and wilted, and eventually die off. The disease can spread quickly, causing the entire plant to die prematurely. If the potatoes are affected by early blight, the symptoms appear as dark, sunken lesions on the skin of the tubers. These lesions can cause the potatoes to rot in storage, making them unsuitable for consumption.`,
      images: [
        require("../../Assets/potato.jpg"),
        require("../../Assets/tomato.jpg"),
      ],
      causes: `Potato Early blight is caused by a fungus called Alternaria solani. The fungus thrives in warm and humid environments and can survive in infected plant debris or soil for long periods. It can also be spread through infected seed potatoes, garden tools, and even by wind and water splashes.

      The disease is more common in areas with high humidity or in regions where potatoes are grown continuously without proper crop rotation. Overcrowding and poor air circulation in the garden can also contribute to the spread of the fungus.
      
      Early blight can be a recurring problem for potato growers, as the fungus can survive in the soil and infect plants in subsequent growing seasons. It's important to practice good crop management, including crop rotation and proper sanitation practices, to minimize the risk of infection.`,
    },
    {
      id: "4",
      name: "Potato Healthy",
      image: require("../../Assets/potato healthy.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Healthy",
      bioName: "Solanum tuberosum",
      sentenceOne: "leaf character will be dark green and healthy",
      symptoms: `Healthy potato leaves are green, sturdy, and have a uniform appearance. The leaves are not wilted or droopy and are supported by strong stems. The foliage appears lush and dense, covering the majority of the plant. In addition, healthy potato leaves have a smooth texture with no blemishes, spots, or discoloration.

      The edges of the leaves should be smooth and regular, and the overall shape of the leaf should be consistent throughout the plant. Healthy leaves will also have a natural shine due to the presence of a waxy coating on the surface of the leaves. This coating helps protect the leaves from moisture loss and disease. Overall, healthy potato leaves are an indicator of a healthy plant that is able to efficiently photosynthesize and produce a strong yield of potatoes.`,
      images: [
        require("../../Assets/potato.jpg"),
        // require("../../Assets/potato healthy.JPG"),
      ],
      causes: `Potato plants with healthy leaves are a sign of a well-maintained and thriving crop. There are several factors that contribute to the healthy growth of potato leaves. One of the most important factors is the proper application of nutrients, including nitrogen, phosphorus, and potassium, which are essential for healthy plant growth. Another factor is adequate water supply, as potato plants require consistent moisture levels to prevent stress and promote healthy foliage. Proper sunlight exposure is also important, as potato plants require at least six hours of sunlight per day. Additionally, proper pest and disease management practices, including regular scouting and appropriate treatments, can prevent damage to the plant leaves and ensure healthy growth. Finally, maintaining appropriate soil pH levels can also contribute to the healthy growth of potato leaves.`,
    },
    {
      id: "5",
      name: "Potato Late Blight",
      image: require("../../Assets/late blight.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Late Blight",
      bioName: "Solanum tuberosum",
      sentenceOne: "Leaf character will be rusted",
      symptoms: `Potato late blight is a serious disease caused by the fungal pathogen Phytophthora infestans. This disease can affect potato plants at any growth stage, but it is most damaging to the crop during the tuber bulking phase. The pathogen overwinters in infected potato tubers and can also survive in plant debris, soil, and on living hosts such as tomato plants.

      The fungus thrives in moist, cool conditions and spreads rapidly through water droplets or wind-driven rain. It enters the plant through wounds or natural openings, such as stomata or hydathodes, and infects the leaves, stems, and tubers. The infection starts as small, gray-green water-soaked spots on the leaves, which later turn brown and necrotic, causing the leaves to wither and die. The disease can also cause blackened areas on the tubers, making them unmarketable.
      
      Poor crop management practices, such as overcrowding, inadequate soil drainage, and failure to control weeds and volunteer plants, can also increase the risk of late blight infection.`,
      images: [
        require("../../Assets/tomato.jpg"),
        require("../../Assets/eggplant.jpg"),
      ],
      causes: `Potato late blight, also known as Phytophthora infestans, is a fungal disease that causes significant damage to potato crops. The disease typically begins in the lower leaves of the plant and progresses upward. The symptoms of late blight can be difficult to identify, especially in the early stages, but as the disease progresses, the symptoms become more obvious.

      The first sign of late blight is usually the appearance of dark, water-soaked spots on the leaves. These spots may enlarge rapidly, and the affected leaves may begin to curl and twist. Eventually, the entire plant may wilt and die. The stems of infected plants may also develop dark lesions and become soft and mushy.
      
      Another symptom of late blight is the appearance of brown spots on the potato tubers. These spots may be shallow at first but can quickly become deep and sunken, making the potatoes unmarketable. In severe cases, the entire crop can be lost, leading to significant economic losses for farmers.`,
    },
  ];

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const params = useParams();

  return (
    <div>
      <Navbar />

      <div className="flex items-center justify-between m-12"></div>

      <div>
        {plant
          ?.filter((z) => z?.bioName === params.plantName)
          ?.map((item) => {
            return (
              <div className="flex justify-center space-x-10">
                {/* <div className=" w-1/12"></div> */}

                <div className="shadow-md w-2/12 items-center h-96">
                  <div
                    style={{
                      color: "#009688",
                    }}
                    className="m-3"
                  >
                    {item?.name}
                  </div>
                  <div className="flex items-center space-x-10 justify-between">
                    <div className="flex space-x-5">
                      <img
                        src={item?.typeImage}
                        style={{ height: 30, width: 30, marginLeft: 10 }}
                        alt="insect"
                      />
                      <div>{item?.type}</div>
                    </div>
                    <div className="px-2">{item?.bioName}</div>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#CCC",
                      height: 2,
                      width: "90%",
                    }}
                    className="m-3"
                  ></div>
                  <div className="m-3">BioName: {item?.bioName}</div>
                  <div className="p-3">{item?.sentenceOne}</div>
                  <div className="p-3">{item?.sentenceOne}</div>
                </div>

                <div className="w-4/12 items-center">
                  <div className="shadow-md ">
                    <div>
                      <img
                        src={item?.image}
                        style={{ height: 250, width: "100%" }}
                        alt="plant"
                      />
                    </div>
                    <div style={{}} className="m-3 text-xl">
                      Symptions
                    </div>
                    <div className="m-3 p-3">{item?.symptoms}</div>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        height: 2,
                        width: "100%",
                      }}
                    ></div>
                    <div className="m-3 p-3"></div>
                  </div>
                  <div className="shadow-md ">
                    <div style={{}} className="m-3 text-xl">
                      Can also be found in
                    </div>

                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        height: 2,
                        width: "100%",
                      }}
                    ></div>
                    <div className="container" onWheel={handleScroll}>
                      {item?.images?.map((z) => {
                        return (
                          <div className="item m-3">
                            <img
                              src={z}
                              style={{ height: 100, width: "100%" }}
                              alt="plant"
                            />
                          </div>
                        );
                      })}

                      <div className="item">{/* Item content */}</div>
                      <div className="item">{/* Item content */}</div>
                    </div>
                  </div>

                  <div className="shadow-md ">
                    <div style={{}} className="m-3 text-xl">
                      What caused it?
                    </div>

                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        height: 2,
                        width: "100%",
                      }}
                    ></div>
                    <div className="m-3 p-3">{item?.causes}</div>
                  </div>
                </div>
                <div className="w-2/12"></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Plant;
