import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <div
        className="flex space-x-5 items-center justify-start  "
        style={{
          backgroundColor: "#009688",
        }}
      >
        <div className="flex items-center space-x-5 ml-10">
          <div className="flex items-center space-x-5 hover:cursor-pointer">
            <Link
              to="/"
              className="flex items-center space-x-5 hover:cursor-pointer"
            >
              <div className=" text-md tracking-widest text-white">Home</div>
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-5">
            <div className="px-3 py-2 rounded-md text-md font-normal hover:cursor-pointer  text-white">
              <Link to="https://weather-monitoring11.netlify.app/">
                Weather monitoring
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden md:flex space-x-5 items-center">
          <div className="px-3 py-2 rounded-md text-md font-normal hover:cursor-pointer hover:font-bold hover:text-black"></div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
