import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

const Home = () => {
  const plants = [
    {
      id: "1",
      name: "Pepper bell-bacterial spot",
      image: require("../../Assets/pepper bac-spot.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Bacterial spot",
      bioName: "Capsicum annuum",
    },
    {
      id: "2",
      name: "Pepper bell-healthy",
      image: require("../../Assets/pepper-healthy.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "healthy",
      bioName: "Capsicum annuum",
    },
    {
      id: "3",
      name: "Potato Early blight",
      image: require("../../Assets/early-blight.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Early Blight",
      bioName: "Solanum tuberosum",
    },
    {
      id: "4",
      name: "Potato Healthy",
      image: require("../../Assets/potato healthy.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Healthy",
      bioName: "Solanum tuberosum",
    },
    {
      id: "5",
      name: "Potato Late Blight",
      image: require("../../Assets/late blight.JPG"),
      typeImage: require("../../Assets/insect.png"),
      type: "Late Blight",
      bioName: "Solanum tuberosum",
    },
  ];

  const [search, setSearch] = useState("");
  return (
    <div>
      <Navbar />

      <div className="flex items-center justify-between m-12">
        <div className=" font-bold text-4xl"> Plant medicine</div>
        <div className=" ">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            style={{
              //   width: 100,
              //   height: 100,
              border: "1px solid black",
              borderRadius: 100,
              padding: 10,
              color: "black",
              marginRight: 20,
            }}
          />{" "}
        </div>
      </div>

      <div className="grid grid-cols-3 m-5">
        {plants
          ?.filter((item) => {
            if (!search) {
              return true;
            }
            if (
              item?.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ) {
              return true;
            }
          })
          ?.map((item) => {
            return (
              <Link key={item?.id} to={`plant/${item?.bioName}`}>
                <div className="shadow-md w-9/12 items-center border-solid border-2 border-white mt-5 rounded-lg">
                  <div>
                    <img
                      src={item?.image}
                      style={{
                        height: 250,
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      alt="plant"
                    />
                  </div>
                  <div
                    style={{
                      color: "#009688",
                    }}
                    className="m-3"
                  >
                    {item?.name}
                  </div>
                  <div className="flex space-x-5 rounded-lg">
                    {/* <img
                      src={item?.typeImage}
                      style={{
                        height: 30,
                        width: 30,
                        marginLeft: 10,
                      }}
                      alt="insect"
                    /> */}
                    <div className="m-1 p-2 text-bold">
                      Disease Type: {item?.type}
                    </div>
                  </div>
                  <div
                    style={{ backgroundColor: "#CCC", height: 2, width: "50%" }}
                    className="mt-4 rounded-lg"
                  ></div>
                  <div className="m-1 p-2 text-bold">
                    BioName: {item?.bioName}
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Home;
